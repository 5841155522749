import React, { useEffect, useRef, useState } from "react";

export const Header = (props) => {
  const [displayedText, setDisplayedText] = useState("");
  const title = props.data ? props.data.title : "";
  const indexRef = useRef(0);

  useEffect(() => {
    const startTyping = () => {
      indexRef.current = -1;
      const intervalId = setInterval(() => {
        setDisplayedText((prev) => prev + title.charAt(indexRef.current));
        indexRef.current += 1;
        if (indexRef.current >= title.length) {
          clearInterval(intervalId);
        }
      }, 120); // Adjust typing speed here
    };
  
    startTyping();
  
    const loopIntervalId = setInterval(() => {
      setDisplayedText(""); // Clear the displayed text before restarting
      startTyping();
    }, 7000); // Run every 5 seconds
  
    return () => {
      clearInterval(loopIntervalId);
    };
  }, [title]);
  

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>{displayedText}</h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                {/* <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Learn More
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
