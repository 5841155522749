import React from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';


export const Navigation = (props) => {
  const navigate = useNavigate();

  const handleScrollToSection = (hash) => {
    console.log(window.location.pathname.split('/'), "window.location.pathname.split('/')")
    if (window.location.pathname.split('/').length > 1)
      navigate(`../#${hash}`);
    else
      window.location.hash = hash;
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {"  "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            Solutioneers Infotech
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            {/* <li>
              <a href="#features" className="page-scroll">
                Features
              </a>
            </li> */}
            <li>
              <a href="#about" className="page-scroll" onClick={() => handleScrollToSection('about')}>
                About
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll" onClick={() => handleScrollToSection('services')}>
                Services
              </a>
            </li>
            {/* <li>
              <a href="#portfolio" className="page-scroll">
                Gallery
              </a>
            </li> */}
            <li>
              <a href="#testimonials" className="page-scroll" onClick={() => handleScrollToSection('testimonials')}>
                Testimonials
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll" onClick={() => handleScrollToSection('team')}>
                Team
              </a>
            </li>
            <li>
              <Link to="/job-openings" className="page-scroll">
                Career
              </Link>
            </li>
            <li>
              <Link to="/training" className="page-scroll">
                Training
              </Link>
            </li>
            <li>
              <a href="#contact" className="page-scroll" onClick={() => handleScrollToSection('contact')}>
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
