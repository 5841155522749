import { Navigation } from "./navigation";
import { Header } from "./header";
import React, { useState, useEffect } from "react";

import { About } from "./about";
import { Services } from "./services";

import { Testimonials } from "./testimonials";
import { Team } from "./Team";
import { Contact } from "./contact";
import JsonData from "../data/data.json";

const Home = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  return (
    <>
      <Navigation />
      <Header data={landingPageData.Header} />
      {/* <Features data={props?.landingPageData?.Features} /> */}
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      {/* <Gallery data={props?.landingPageData?.Gallery} /> */}
      <Testimonials data={landingPageData.Testimonials} />
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} />
      {/* Define the route for the landing page */}
    </>
  );
};

export default Home;
