import React, { useState } from "react";
import Button from "@mui/material/Button";
import data from "../data/data.json";
import { Container, Typography } from "@mui/material";
import { Navigation } from "./navigation";

const TrainingPage = () => {
  const [expandedTrainingId, setExpandedTrainingId] = useState(null);

  const toggleTrainingDescription = (id) => {
    setExpandedTrainingId(expandedTrainingId === id ? null : id);
  };

  const trainingsData = data.Trainings;

  return (
    <div className="container mt-5 training">
        <Navigation />
        <Container>
      <h1 className="mb-4" style={{ fontSize: "3.5rem", paddingTop: "100px", paddingBottom: "30px", color:"#675c50"}}>
        Trainings & Workshops
      </h1>
      <ul className="list-group">
        {trainingsData.map((job) => (
          <li key={job.id} className="list-group-item">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <strong h6 style={{ fontSize: "2.2rem" }}>{job.title}</strong>{"  "}
                {job.role}
              </div>
              <Button onClick={() => toggleTrainingDescription(job.id)} sx={{ pt: 2.5, pb: 2 }}>
                <Typography fontSize={11}>{expandedTrainingId === job.id ? "Read Less" : "Read More"}</Typography>
              </Button>
            </div>
            {expandedTrainingId === job.id && (
              <div className="mt-3">
                <h5>Job Description:</h5>
                <p>{job.description.jobDescription}</p>
                <h5>Responsibilities:</h5>
                <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  {job.description.responsibilities.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
                <h5>Qualifications:</h5>
                <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                  {job.description.qualifications.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
                <a
                  href={job.registration_link}
                  target="blank"
                >
                  <Button variant="outlined" sx={{ mt: 2, mr: 2, pr: 2 }} fullWidth color="success">
                    <Typography variant="h6">Register Now</Typography>
                  </Button>
                </a>
              </div>
            )}
          </li>
        ))}
      </ul>
      </Container>
    </div>
  );
};

export default TrainingPage;
